import { ChatIcon } from '@mybridge/icons';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Heading,
  IconButton,
  Stack,
  Text,
  Card,
} from '@mybridge/ui';
import Link from 'next/link';
import { forwardRef, useContext, useEffect } from 'react';
import {
  getUserFullName,
  getUserLocation,
  getUserPosition,
  getUserProfilePic,
} from 'v4/lib/commons';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { MyNetworkContext } from 'v4/components/network/context';
import { SuggestedUserCard } from 'v4/components/user-card/suggested';
import { useRouter } from 'next/router';
import { StackDivider } from '@chakra-ui/react';
import { PublicProfileContext } from 'v4/components/public-profile/context';
import Slider from 'react-slick';
import SuggestionUserCardBox from './Suggestion-User-Card';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { MyNetworkUserCardBordered } from 'v4/components/network/network-card-new';
import { UserNetworkingActions } from 'v4/components/user-networking-actions';
export const MyProfileNetworkSuggestionsCarousel = forwardRef(
  ({ displayCount, title, ...props }, ref) => {
    // const { connections = [] } = useContext(PublicProfileContext);
    const router = useRouter();
    const {
      suggestions,
      allSuggestions,
      refetchSuggestions,
      suggestionsIsFetching,
      suggestionsIsLoading,
    } = useContext(MyNetworkContext);
    useEffect(() => {
      refetchSuggestions?.();
    }, []);

    const settings = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: props.isFromDashbord ? 3.3 : 3,
      slidesToScroll: props.isFromDashbord ? 3 : 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: props.isFromDashbord ? 3.3 : 3,
            slidesToScroll: props.isFromDashbord ? 3 : 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: props.isFromDashbord ? 3.3 : 3,
            slidesToScroll: props.isFromDashbord ? 3 : 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: props.isFromDashbord ? 2.3 : 2,
            slidesToScroll: props.isFromDashbord ? 2 : 1,
          },
        },
      ],
    };

    return (
      <>
        <Card variant="thinBorderedCard" borderTop="1px solid #e0e0e094" px={"6px"} marginLeft={"1.5px"} marginRight={"2px"}  borderLeft="1px solid #e0e0e094" borderRight="1px solid #e0e0e094" p={3} borderRadius="0px" boxShadow="none">
          <HStack justifyContent="space-between" mb={2}>
            <Heading color="brandPrimary.500" size="md" fontWeight="400">
              People you may know
            </Heading>
            <Button
              variant="transparent"
              onClick={() => router.push('/profile/network/manage')}
            >
              See all
            </Button>
          </HStack>
          {suggestionsIsLoading ? (
            <SectionLoader />
          ) : (
            <>
              {allSuggestions?.length ? (
                <Slider {...settings}>
                  {allSuggestions?.map((con, conIndex) => (
                    <MyNetworkUserCardBordered
                      user={con}
                      key={conIndex}
                      actions={
                        <UserNetworkingActions
                          user={con}
                          px={3}
                          pt={3}
                          showHandshakeOnly
                          justifyContent="center"
                        />
                      }
                    />
                  ))}
                </Slider>
              ) : (
                ''
              )}
            </>
          )}
        </Card>
      </>
    );
  }
);
